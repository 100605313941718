import React from 'react'
import Slider from 'react-slick'
import FooterSlider from './FooterSlider'
import ScrollToTop from './ScrollToTop'
import BackToTopButton from './BakctoTop'
import {Helmet} from "react-helmet"
const About = () => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 770
  return (
<div className="content-wrapper">
<Helmet>
            <meta charset="utf-8" />
            <meta name="description" content="Hire luxury limo, professional airport transportation, SUV, and sedan services in Columbia, MD. We provide affordable party limo, chauffeur-driven prom car, late-night SUV, private executive airport transportation, and more in Harford County, Baltimore County, Annapolis City, and Harford County. Book Now!" />
            <link rel="canonical" href="https://92limo.com/about-us" />
            <title>92 Limo - Super VIP experience - Book Today!</title>
        </Helmet>
<ScrollToTop/>
<BackToTopButton/>
  <div className="limoking-content">
    <div className="with-sidebar-wrapper">
      <section id="content-section-1" data-aos="fade-up"
                data-aos-delay="100">
        <div
          className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-dark-skin"
          id="limoking-parallax-wrapper-1"
          data-bgspeed={0}
          style={{
            backgroundImage: 'url("upload/title-big-bg1.jpg")',
            paddingTop: 150,
            paddingBottom: 70
          }}
        >
          <div className="container">
            <div className="limoking-title-item">
              <div className="limoking-item-title-wrapper limoking-item limoking-center-icon-divider limoking-large">
                <div className="limoking-item-title-container container">
                  <div className="limoking-item-title-head-inner">
                    <h3 className="limoking-item-title limoking-skin-title limoking-skin-border">
                      About 92 Limo
                    </h3>
                  </div>
                  <div className="limoking-nav-title">
                    <div className="limoking-item-title-content-icon-divider-wrapper">
                      <div className="limoking-item-title-center-icon-divider" />
                      <div className="limoking-item-title-center-icon-divider" />
                    </div>
                  </div>
                  <div className="limoking-item-title-caption limoking-skin-info">
                    We are the most popular limousine service in Maryland, Virginia and Washington DC.
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
            <div className="clear" />
          </div>
        </div>
        <div className="clear" />
      </section>
      <section data-aos="fade-up"
                data-aos-delay="100" id="content-section-2">
        <div
          className="limoking-color-wrapper gdlr-show-all no-skin"
          style={{
            backgroundColor: "#ffffff",
            paddingTop: 70,
            paddingBottom: 30
          }}
        >
          <div className="container">
            <div className="six columns">
              <div className="limoking-image-frame-item limoking-item">
                <div className="limoking-frame limoking-link-type-none frame-type-none">
                  <div className="limoking-image-link-shortcode">
                    <img
                      src="upload/about-car1.jpg"
                      alt="about-car1"
                      width={546}
                      height={321}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="six columns">
              <div className="limoking-item limoking-about-us-item limoking-plain">
                <div className="about-us-title-wrapper">
                  <h3 className="about-us-title">
                  Choose 92 Limo for Quality Chauffeur Service in Maryland
                  </h3>
                </div>
                <div className="about-us-content-wrapper">
                  <div className="about-us-content limoking-skin-content">
                    <p>
                    We're here to provide you with a comfortable and reliable ride whenever you need it:<br/>

                    Our friendly drivers and well-maintained vehicles ensure your journey is smooth and stress-free, <br/> 
                    whether you're traveling to the airport or attending a special event. <br/>
                    and classy ride throughout Maryland. <br/>


                    </p>
                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div className="clear" />
      </section>
      <section data-aos="fade-up"
                data-aos-delay="100" id="content-section-3">
        <div
          className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-dark-skin"
          id="limoking-parallax-wrapper-2"
          data-bgspeed="0.1"
          style={{
            backgroundImage: 'url("upload/skill-bg1.jpg")',
            paddingTop: 95,
            paddingBottom: 65
          }}
        >
          <div className="container">
            <div style={{width:'50%'}} className="two-fifth columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  9k
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  HAPPY CUSTOMERS
                </div>
              </div>
            </div>
            {/* <div className="three columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  12
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  LUXURY LIMOS
                </div>
              </div>
            </div> */}
            <div style={{width:'50%'}} className="two-fifth columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  900k
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  MILES
                </div>
              </div>
            </div>
            {/* <div className="three columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  30
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  QUALIFIED CHAUFFUERS
                </div>
              </div>
            </div> */}
            <div className="clear" />
          </div>
        </div>
        <div className="clear" />
      </section>
            <FooterSlider/>

    </div>
  </div>
  <div className="clear" />
</div>

  )
}

export default About