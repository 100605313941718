import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LogoMarqueeSlider = ({logos,rtl}) => {


    const settings = {
        infinite: true,
        speed: 4500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        rtl: rtl,
        autoplaySpeed: 0,
        arrows:false,
        cssEase: 'linear',
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <div className="logo-marquee-slider">
            <Slider {...settings}  >
                {logos?.map(logo => (
                    <div key={logo.id}>
                    <div style={{background:'#f2f2f2',borderColor:'#eeeeee',boxShadow:'0 0 10px rgba(0, 0, 0, 0.1)',margin:'12px'}}>
                        <img src={logo.imageUrl} alt={logo.alt} />
                    </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LogoMarqueeSlider;
