import { Route, Routes } from "react-router-dom";
import Home from "../Home";
import About from "../About";
import Blog from "../Blog";
import ContactUs from "../ContactUs";
import PrivacyPolicy from "../privacyPolicy";
import Service1 from "../Service1";
import Service2 from "../Service2";
import Service3 from "../Service3";
import Service4 from "../Service4";
const Routers = () => {
	return (
		<Routes>
			<Route path="/" element={[<Home />,]} />
			<Route path="/about-us" element={<About />} />
			<Route path="/blog" element={<Blog />} />
			<Route path="/contact-us" element={<ContactUs />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/limousine-services" element={<Service1 />} />
			<Route path="/airport-transportation-services" element={<Service2 />} />
			<Route path="/suv-services" element={<Service3 />} />
			<Route path="/sedan-services" element={<Service4 />} />
			
		</Routes>
	);
};

export default Routers;