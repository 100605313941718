/* eslint-disable jsx-a11y/anchor-is-valid */
import 'flexslider';
import 'flexslider/flexslider.css';
import "./App.css";
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "aos/dist/aos.css";
import AOS from "aos";
import { BrowserRouter } from 'react-router-dom';
import './css/masterslider-custom.css'
import Slider from 'react-slick'
import './css/style-custom.css'
import './css/style.css'
import './css/style-responsive.css'
import './css/main.css'
import Routers from './components/Routes/Routes';
import { useLocation } from 'react-router-dom'
import { FaFacebook, FaPhoneAlt } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { BiMapPin } from 'react-icons/bi';
import { BsFacebook, BsInstagram, BsLinkedin, BsX, BsYoutube } from 'react-icons/bs';
import SocialIcon from './components/icon/Icon';
import Layout from './components/Layout';

function App() {


  useEffect(() => {
    AOS.init();
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const [active, setActive] = useState(false);

  // const PreviousBtn = (props) => {
  //   console.log(props);
  //   const { className, onClick } = props;
  //   return (
  //     <div className={className} onClick={onClick}>
  //       <lessThan style={{ color: "white", fontSize: "30px" }} />
  //     </div>
  //   );
  // };

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 770

  const handleClick = () => {
    console.log('ok');
    if (active) {
      setActive(false)
    } else {
      setActive(true)
    }
  }



  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const triggerPoint = window.innerHeight * 0.05; // 20% of viewport height
      setIsFixed(scrollTop > triggerPoint);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll); // Cleanup
  }, []);


  const services = [
    "Point-to-Point Transfer",
    "Hourly Charter",
    "Airport Transfer",
    "Private Airport Transportation",
    "Group & Events",
    "Road Shows",
    "Corporate Transfer",
    "City Tours",
    "Chauffeur Service",
    "Luxury Travel",
    "Long-Distance Trips",
    "Vaccination Car Service"
  ]

  const Special = [
    "Bachelor Party Limo Service",
    "Bachelorette Party Limo Service",
    "Birthday Limo Service",
    "Honeymoon Transfer Service",
    "Kids & Teens Limo Service",
    "Prom Limo Service",
    "Vip Bottle Service",
    "Vip Hosted Entry",
    "Wedding Limo Service"
  ]

  const cities = [
    "Associates",
    "Chauffeur Partners Brazil",
    "Chauffeur Partners US",
    "FBO Partners",
    "Booking Guide",
    "Blog",
    "FAQ",
    "Contact"
  ]


  return (
    <BrowserRouter>
    <Layout><Routers/></Layout>
    </BrowserRouter>
  );
}

export default App;