import React from 'react'
import FooterSlider from './FooterSlider'
import ScrollToTop from './ScrollToTop'
import BackToTopButton from './BakctoTop'

const Blog = () => {


    const JSON = [
        {
            image : 'upload/car-41.png',
            title : 'Donec luctus imperdiet',
            date : '03 Dec 2013',
            postBy : 'John Doe' ,
            content : 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Idque Caesaris facere voluntate liceret:sese habere. Magna pars studiorum, prodita quaerimus. Magna pars studiorum, prodita quaerimus. Fabio vel iudice vincam, sunt in culpa qui officia. Vivamus sagittis lacus vel augue laoreet...'

        },
        {
            image : 'upload/shutterstock_1417049411-1024x683.jpg',
            title : 'Donec luctus imperdiet',
            date : '03 Dec 2013',
            postBy : 'John Doe' ,
            content : 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Idque Caesaris facere voluntate liceret:sese habere. Magna pars studiorum, prodita quaerimus. Magna pars studiorum, prodita quaerimus. Fabio vel iudice vincam, sunt in culpa qui officia. Vivamus sagittis lacus vel augue laoreet...'

        },
        {
            image : 'upload/shutterstock_1417049411-1024x683.jpg',
            title : 'Donec luctus imperdiet',
            date : '03 Dec 2013',
            postBy : 'John Doe' ,
            content : 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Idque Caesaris facere voluntate liceret:sese habere. Magna pars studiorum, prodita quaerimus. Magna pars studiorum, prodita quaerimus. Fabio vel iudice vincam, sunt in culpa qui officia. Vivamus sagittis lacus vel augue laoreet...'

        },
        {
            image : 'upload/shutterstock_1417049411-1024x683.jpg',
            title : 'Donec luctus imperdiet',
            date : '03 Dec 2013',
            postBy : 'John Doe' ,
            content : 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Idque Caesaris facere voluntate liceret:sese habere. Magna pars studiorum, prodita quaerimus. Magna pars studiorum, prodita quaerimus. Fabio vel iudice vincam, sunt in culpa qui officia. Vivamus sagittis lacus vel augue laoreet...'

        },
    ]


  return (
    <div>
    <ScrollToTop/>
<BackToTopButton/>
<>
  <div className="limoking-page-title-wrapper header-style-2-title-wrapper">
    <div className="limoking-page-title-overlay" />
    <div className="limoking-page-title-container container">
      <h1 className="limoking-page-title">Blog 1 Column</h1>
      <span className="limoking-page-caption">Caption placed here</span>
    </div>
  </div>
  <div className="content-wrapper">
    <div className="limoking-content">
      <div className="with-sidebar-wrapper">
        <div className="with-sidebar-container container">
          <div className="with-sidebar-left eight columns">
            <div className="with-sidebar-content twelve columns">
              <section id="content-section-1">
                <div className="section-container container">
                  <div className="blog-item-wrapper">
                    <div className="blog-item-holder">
                      <div
                        className="limoking-isotope"
                        data-type="blog"
                        data-layout="fitRows"
                      >
                        <div className="clear" />
                        {
                            JSON.map((item) => {
                                return (
                        <div className="twelve columns">
                          <div className="limoking-item limoking-blog-grid limoking-skin-box">
                            <div className="limoking-ux limoking-blog-grid-ux">
                              <article
                                id="post-852"
                                className="post-852 post type-post status-publish format-standard has-post-thumbnail hentry category-fit-row tag-blog tag-life-style"
                              >
                                <div className="limoking-standard-style">
                                  <div className="limoking-blog-thumbnail">
                                    <a href="../donec-luctus-imperdiet/index.html">
                                      <img
                                        src="upload/shutterstock_1417049411-1024x683.jpg"
                                        alt="shutterstock_1417049411-1024x683"
                                        width={960}
                                        height={640}
                                      />
                                    </a>
                                  </div>
                                  <div className="limoking-blog-grid-content">
                                    <header className="post-header">
                                      <h3 className="limoking-blog-title">
                                        <a href="../donec-luctus-imperdiet/index.html">
                                          {item.title}
                                        </a>
                                      </h3>
                                      <div className="limoking-blog-info limoking-info-font">
                                        <div className="blog-info blog-date limoking-skin-info">
                                        <i className="icon_clock_alt">

                                        </i>
                                          <a href="../2013/12/03/index.html">
                                            {item.date}
                                          </a>
                                        </div>
                                        <div className="blog-info blog-author limoking-skin-info">
                                        <i className="icon_comment_alt"></i>
                                          By{" "}
                                          <a
                                            href="../author/superuser/index.html"
                                            title="Posts by John Doe"
                                            rel="author"
                                          >
                                            {item.postBy}
                                          </a>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                      <div className="clear" />
                                    </header>
                                    <div className="limoking-blog-content">
                                      {
                                        item.content
                                      }
                                      <div className="clear" />
                                      <a
                                        href="../donec-luctus-imperdiet/index.html"
                                        className="limoking-button large excerpt-read-more"
                                      >
                                        Read More
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                                )
                            })
                        }
                      </div>
                    </div>
                    {/* <div className="limoking-pagination">
                      <span
                        aria-current="page"
                        className="page-numbers current"
                      >
                        1
                      </span>{" "}
                      <a className="page-numbers" href="page/2/index.html">
                        2
                      </a>{" "}
                      <a className="next page-numbers" href="page/2/index.html">
                        Next ›
                      </a>
                    </div> */}
                  </div>
                  <div className="clear" />
                </div>
              </section>
            </div>
            <div className="clear" />
          </div>
          <div className="limoking-sidebar limoking-right-sidebar four columns">
            <div className="limoking-item-start-content sidebar-right-item">
              {/* <div
                id="search-3"
                className="widget widget_search limoking-item limoking-widget"
              >
                <div className="gdl-search-form">
                  <form
                    method="get"
                    id="searchform"
                    action="https://demo.goodlayers.com/limoking/"
                    name="searchform"
                  />
                  <div className="search-text" id="search-text">
                    <input
                      type="text"
                      name="s"
                      id="s"
                      autoComplete="off"
                      data-default="Type keywords..."
                    />
                  </div>
                  <input type="submit" id="searchsubmit" defaultValue="" />
                  <div className="clear" />
                </div>
              </div> */}
              <div
                id="text-2"
                className="widget widget_text limoking-item limoking-widget"
              >
                <h3 className="limoking-widget-title">Text Widget</h3>
                <div className="clear" />
                <div className="textwidget">
                  Sed posuere consectetur est at lobortis. Donec id elit non mi
                  porta gravida at eget metus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Nullam id dolor id nibh ultricies
                  vehicula ut id elit.
                </div>
              </div>
              <div
                id="gdlr-recent-portfolio-widget-2"
                className="widget widget_gdlr-recent-portfolio-widget limoking-item limoking-widget"
              >
                <h3 className="limoking-widget-title">Recent Works</h3>
                <div className="clear" />
                <div className="limoking-recent-port-widget">
                  <div className="recent-post-widget">
                    <div className="recent-post-widget-thumbnail">
                      <a href="../portfolio/night-party/index.html">
                        <img
                          src="upload/shutterstock_770756561-150x150.jpg"
                          alt="shutterstock_770756561-150x150"
                          width={150}
                          height={150}
                        />
                      </a>
                    </div>
                    <div className="recent-post-widget-content">
                      <div className="recent-post-widget-title">
                        <a href="../portfolio/night-party/index.html">
                          Night Party
                        </a>
                      </div>
                      <div className="recent-post-widget-info">
                        <div className="blog-info blog-date limoking-skin-info">
                        <i className="icon_clock_alt"></i>
                          <a href="../2013/12/04/index.html">04 Dec 2013</a>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="recent-post-widget">
                    <div className="recent-post-widget-thumbnail">
                      <a href="../portfolio/business-taxi-service/index.html">
                        <img
                          src="upload/shutterstock_1058269641-150x150.jpg"
                          alt="shutterstock_1058269641-150x150"
                          width={150}
                          height={150}
                        />
                      </a>
                    </div>
                    <div className="recent-post-widget-content">
                      <div className="recent-post-widget-title">
                        <a href="../portfolio/business-taxi-service/index.html">
                          Business Taxi Service
                        </a>
                      </div>
                      <div className="recent-post-widget-info">
                        <div className="blog-info blog-date limoking-skin-info">
                        <i className="icon_clock_alt"></i>
                          <a href="../2013/12/04/index.html">04 Dec 2013</a>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="recent-post-widget">
                    <div className="recent-post-widget-thumbnail">
                      <a href="../portfolio/airport-transfers/index.html">
                        <img
                          src="upload/shutterstock_1837970511-150x150.jpg"
                          alt="shutterstock_1837970511-150x150"
                          width={150}
                          height={150}
                        />
                      </a>
                    </div>
                    <div className="recent-post-widget-content">
                      <div className="recent-post-widget-title">
                        <a href="../portfolio/airport-transfers/index.html">
                          Airport Transfers
                        </a>
                      </div>
                      <div className="recent-post-widget-info">
                        <div className="blog-info blog-date limoking-skin-info">
                        <i className="icon_clock_alt"></i>
                          <a href="../2013/12/04/index.html">04 Dec 2013</a>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="clear" />
                </div>
              </div>
              <div
                id="recent-comments-3"
                className="widget widget_recent_comments limoking-item limoking-widget"
              >
                <h3 className="limoking-widget-title">Recent Comments</h3>
                <div className="clear" />
                <ul id="recentcomments">
                  <li className="recentcomments">
                    <span className="comment-author-link">John Doe</span> on{" "}
                    <a href="../product/classy-men-shoes/index.html#comment-39">
                      Classy Men Shoes
                    </a>
                  </li>
                  <li className="recentcomments">
                    <span className="comment-author-link">John Doe</span> on{" "}
                    <a href="../product/blue-casual-suit/index.html#comment-37">
                      Blue Casual Suit
                    </a>
                  </li>
                  <li className="recentcomments">
                    <span className="comment-author-link">John Doe</span> on{" "}
                    <a href="../product/dark-skirt/index.html#comment-36">
                      Dark Skirt
                    </a>
                  </li>
                  <li className="recentcomments">
                    <span className="comment-author-link">John Doe</span> on{" "}
                    <a href="../product/dark-skirt/index.html#comment-35">
                      Dark Skirt
                    </a>
                  </li>
                  <li className="recentcomments">
                    <span className="comment-author-link">John Doe</span> on{" "}
                    <a href="../product/handsome-style/index.html#comment-31">
                      Handsome Style
                    </a>
                  </li>
                </ul>
              </div>
              <div
                id="tag_cloud-2"
                className="widget widget_tag_cloud limoking-item limoking-widget"
              >
                <h3 className="limoking-widget-title">Tag Cloud</h3>
                <div className="clear" />
                <div className="tagcloud">
                  <a
                    href="../tag/animal/index.html"
                    className="tag-cloud-link tag-link-11 tag-link-position-1"
                    style={{ fontSize: "8pt" }}
                    aria-label="Animal (1 item)"
                  >
                    Animal
                  </a>{" "}
                  <a
                    href="../tag/aside/index.html"
                    className="tag-cloud-link tag-link-12 tag-link-position-2"
                    style={{ fontSize: "8pt" }}
                    aria-label="Aside (1 item)"
                  >
                    Aside
                  </a>{" "}
                  <a
                    href="../tag/audio/index.html"
                    className="tag-cloud-link tag-link-13 tag-link-position-3"
                    style={{ fontSize: "11.230769230769pt" }}
                    aria-label="Audio (2 items)"
                  >
                    Audio
                  </a>{" "}
                  <a
                    href="../tag/blog/index.html"
                    className="tag-cloud-link tag-link-14 tag-link-position-4"
                    style={{ fontSize: "19.666666666667pt" }}
                    aria-label="Blog (8 items)"
                  >
                    Blog
                  </a>{" "}
                  <a
                    href="../tag/business/index.html"
                    className="tag-cloud-link tag-link-15 tag-link-position-5"
                    style={{ fontSize: "15.179487179487pt" }}
                    aria-label="Business (4 items)"
                  >
                    Business
                  </a>{" "}
                  <a
                    href="../tag/identity-2/index.html"
                    className="tag-cloud-link tag-link-17 tag-link-position-6"
                    style={{ fontSize: "13.384615384615pt" }}
                    aria-label="identity (3 items)"
                  >
                    identity
                  </a>{" "}
                  <a
                    href="../tag/life-style/index.html"
                    className="tag-cloud-link tag-link-18 tag-link-position-7"
                    style={{ fontSize: "22pt" }}
                    aria-label="Life Style (11 items)"
                  >
                    Life Style
                  </a>{" "}
                  <a
                    href="../tag/link/index.html"
                    className="tag-cloud-link tag-link-19 tag-link-position-8"
                    style={{ fontSize: "11.230769230769pt" }}
                    aria-label="Link (2 items)"
                  >
                    Link
                  </a>{" "}
                  <a
                    href="../tag/news/index.html"
                    className="tag-cloud-link tag-link-20 tag-link-position-9"
                    style={{ fontSize: "15.179487179487pt" }}
                    aria-label="News (4 items)"
                  >
                    News
                  </a>{" "}
                  <a
                    href="../tag/post-format/index.html"
                    className="tag-cloud-link tag-link-21 tag-link-position-10"
                    style={{ fontSize: "15.179487179487pt" }}
                    aria-label="Post format (4 items)"
                  >
                    Post format
                  </a>{" "}
                  <a
                    href="../tag/quote/index.html"
                    className="tag-cloud-link tag-link-22 tag-link-position-11"
                    style={{ fontSize: "8pt" }}
                    aria-label="Quote (1 item)"
                  >
                    Quote
                  </a>{" "}
                  <a
                    href="../tag/safari/index.html"
                    className="tag-cloud-link tag-link-23 tag-link-position-12"
                    style={{ fontSize: "8pt" }}
                    aria-label="Safari (1 item)"
                  >
                    Safari
                  </a>{" "}
                  <a
                    href="../tag/travel/index.html"
                    className="tag-cloud-link tag-link-24 tag-link-position-13"
                    style={{ fontSize: "8pt" }}
                    aria-label="Travel (1 item)"
                  >
                    Travel
                  </a>{" "}
                  <a
                    href="../tag/video/index.html"
                    className="tag-cloud-link tag-link-25 tag-link-position-14"
                    style={{ fontSize: "8pt" }}
                    aria-label="Video (1 item)"
                  >
                    Video
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clear" />
        </div>
      </div>
    </div>
    <FooterSlider/>
    <div className="clear" />
  </div>
</>


    </div>
  )
}

export default Blog
