import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom'
function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    message: ''
  });

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(JSON.stringify(formData)); // You can handle form submission logic here
    // Optionally, you can send form data to a server using fetch or axios
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fullName">Full Name:</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div style={{display: "flex", alignItems: "start", justifyContent: 'center'}}>
        <input type="checkbox" 
          // required 
        />
        <p style={{marginTop: "-3px", fontSize: "14px"}}>By clicking this box, you agree to receive SMS communications from 92 Limo Service. You can reply STOP at any time to opt-out. See here <span onClick={()=>navigate('/privacy-policy')} style={{textDecoration: 'underline', cursor: "pointer"}}>privacy policy</span></p>
        </div>
        <button style={{background:'rgb(213, 157, 35)' ,padding:'15px 28px', color:'white'}} type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactForm;
