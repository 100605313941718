import React from 'react';
import { LeftSectionContent, LeftSection } from './LeftContent';
import LogoMarqueeSlider from './HomeSlider';
import './main.css'
import "aos/dist/aos.css";
import AOS from "aos";

const MainSection = () => {


    React.useEffect(() => {
        AOS.init();
    }, []);
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 770

    const logos = [
        { id: 1, imageUrl: 'upload/airport1.png', alt: 'Logo 1' },
        { id: 2, imageUrl: 'upload/airport.png', alt: 'Logo 2' },
        { id: 3, imageUrl: 'upload/airport3.png', alt: 'Logo 3' },
        { id: 3, imageUrl: 'upload/SignatureAirport-1.png', alt: 'Logo 3' },
        { id: 3, imageUrl: 'upload/SignatureAirport-2.png', alt: 'Logo 3' },
        // Add more logos as needed
    ];
    const trainStations = [
        { id: 1, imageUrl: 'upload/Train Station-1.png', alt: 'Logo 1' },
        { id: 2, imageUrl: 'upload/Train Station-2.png', alt: 'Logo 2' },
        { id: 3, imageUrl: 'upload/Train Station-3.png', alt: 'Logo 3' },
        { id: 3, imageUrl: 'upload/Train Station-4.png', alt: 'Logo 3' },
        // Add more logos as needed
    ];

    return (
        <>
            <LeftSectionContent title='Major Locations we cover' />
            <div className="main-section" data-aos="fade-up" data-aos-delay="300">
                <div className="left-side">
                    <LeftSection title='Airports | Signature Airports' />
                </div>
                <div className="right-side">
                    <LogoMarqueeSlider logos={logos} rtl={true} />
                </div>
            </div>
            <div style={{flexDirection: isMobile ? 'column-reverse' : 'row'}} className="main-section" data-aos="fade-up" data-aos-delay="300">
                <div className="right-side">
                    <LogoMarqueeSlider logos={trainStations} rtl={false} />
                </div>
                <div className="left-side">
                    <LeftSection title='Train Stations' />
                </div>
            </div>
        </>
    );
};

export default MainSection;
