import React, { useState } from 'react';
import styles from './SocialIcon.module.css';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin, FaTiktok } from 'react-icons/fa';

const SocialIcon = ({ type }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const getIcon = () => {
    switch (type) {
      case 'facebook':
        return <FaFacebook />;
      case 'twitter':
        return <FaTwitter />;
      case 'instagram':
        return <FaInstagram />;
      case 'youtube':
        return <FaYoutube />;
      case 'linkedin':
        return <FaLinkedin />;
      case 'tikTok':
        return <FaTiktok />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`${styles.icon} ${isHovered && styles.hover}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {getIcon()}
    </div>
  );
};

export default SocialIcon;
