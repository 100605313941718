import React from 'react'
import Slider from 'react-slick'
import FooterSlider from './FooterSlider'
import ScrollToTop from './ScrollToTop'
import BackToTopButton from './BakctoTop'
import { useLocation, useNavigate } from 'react-router-dom'
import {Helmet} from "react-helmet"

const Service1 = () => {
  

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 770
  const navigate = useNavigate();
  const location = useLocation()
  const settings = {
    dots: false,
    infinite: false,
    speed: 0,
    slidesToShow: 1,
     arrows:false,
    slidesToScroll: 1,
    draggable: false,
};

return (
<div className="content-wrapper">

<Helmet>
    <meta charSet="utf-8" />
    <title>Hire Professional Limousine Services in Columbia, MD</title>
    <meta name="description" content="Hire luxury Limo & Proms services through best Wedding Limousine transportation services in Columbia. We are providing affordable party limo & chauffeur-driven prom car hire services in Harford, Baltimore County, Annapolis City, and Harford County. Book Now!" />
    <link rel="canonical" href="https://92limo.com/limousine-services" />
</Helmet>

<ScrollToTop/>
<BackToTopButton/>
  <div className="limoking-content">
    <div className="with-sidebar-wrapper">
    <Slider {...settings} swipe={true} >
                <div className="item">
                    <div className='carousel_content' style={{height: 'content-fit'}}>
                        <img alt='background-img' src='services/Background image Limousine Services page.jpg' style={{ height: isMobile ? '229px' : '650px', objectFit: 'fill' }} />
                        <div className='content_text' style={{backgroundColor:'rgba(0,0,0,0.6)', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10}}>
                            <h1
                                className="ms-layer msp-cn-2-1"
                                style={{textAlign:'center', fontSize: isMobile ? "24px" :'42px'}}
                                data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                                data-duration={375}
                                data-ease="easeOutQuint"
                                data-offset-x={0}
                                data-offset-y={-111}
                                data-origin="mc"
                                data-position="normal"
                            >
                                Experience Luxury and Elegance with Our Professional Limousine Services in Columbia, MD
                            </h1>
                            <div
                                className="ms-layer msp-cn-2-2"
                                style={{textAlign:'center', fontSize: isMobile ? "18px" :'32px'}}
                                data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                                data-duration={375}
                                data-delay={300}
                                data-ease="easeOutQuint"
                                data-offset-x={0}
                                data-offset-y={-36}
                                data-origin="mc"
                                data-position="normal"
                            >
                                Discover unparalleled comfort and style with our premier limo service, airport limo rentals, and more.
                            </div>
                            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20}} className='container'>
                  <button data-aos="fade-right"
                      data-aos-delay="500"
                       style={{width: '150px', height: '50px'}}
                                        className="limoking-button"
                                        onClick={() =>{ 
                                          if(location.pathname === '/') {
                                                window.scrollTo({
                                                    top: 400,
                                                    left: 0,
                                                    behavior: 'smooth'
                                                });
                                            }else {
                                                navigate('/', { state: { scrolltoTop: true } })
                                            }
                                          }
                                        }
                                    >
                                        Book Now
                  </button>
                  <button data-aos="fade-left"
                data-aos-delay="500"
                       style={{width: '150px', height: '50px'}}
                                        className="limoking-button"
                                        onClick={() => navigate('/contact-us')}
                                    >
                                        Contact Us
                  </button>
                </div>
                            

                        </div>

                    </div>
                </div>
            </Slider>
     <section style={{padding: '50px', fontSize: '18px', textAlign:'center'}} className='' data-aos="fade-up" data-aos-delay="100">
     In the bustling heart of Columbia, MD, where every moment counts and impressions matter, our professional limousine services redefine luxury travel. Whether you're jetting off from BWI Airport, celebrating a special occasion in Howard County, or attending a corporate event in Baltimore County, our fleet of meticulously maintained vehicles and expert chauffeurs ensure your journey is as memorable as your destination.
     </section>
     <section style={{display: 'grid',gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap: '50px', backgroundColor: 'rgba(213, 157, 35, 0.9)', padding: '50px'}}
        data-aos="fade-up"
        data-aos-delay="100"
        className=''>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img alt='section-1-img' src='services/Limousine Services page section 01 image.jpg' style={{ height: isMobile ? '350px' : '100%', objectFit: 'cover', objectPosition: 'center', width: '100%', }} />
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} >
              <h2 style={{color: 'white'}}>Rent a Limo for Prom Transportation Services in Columbia, MD</h2>
              <p style={{color: 'white', textAlign: 'justify', fontSize: '16px'}}>
                Elevate your prom night in Columbia, MD with our exquisite prom transportation services. We specialize in luxury prom car rentals, ensuring a memorable experience that complements your special night perfectly. Choose from a selection of elegant vehicles, including sleek limousines, a prom party bus, and stylish party buses, all equipped with state-of-the-art amenities to enhance your journey.
                  <br/>
                  <br/>
                  Whether you're looking for affordable options like cheap limos for prom or prefer the luxury of a prom car chauffeur service in Baltimore County, we cater to every preference with professionalism and care. Our local prom car hire services extend across Howard County, Annapolis City, and Harford County, ensuring convenience and reliability wherever your prom venue may be.
              </p>
          </div>
     </section>
     <section style={{padding: '50px', fontSize: '18px', textAlign:'center'}}  data-aos="fade-up" data-aos-delay="100">
     Make your prom night or special event memorable with our luxurious party limo and chauffeur-driven prom car hire services in Harford County. Whether it's a stylish prom vehicle rental or a fun-filled party limo, trust us to add glamour and safety to your celebrations.
     </section>
     <section style={{display: 'grid',gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap: '50px', backgroundColor: 'rgba(213, 157, 35, 0.9)', padding: '50px'}}
        data-aos="fade-up"
        data-aos-delay="100"
        className=''>
          
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} >
              <h2 style={{color: 'white'}}>Wedding Limousine Transportation Services in Columbia, MD</h2>
              <p style={{color: 'white', textAlign: 'justify', fontSize: '16px'}}>
              Planning your wedding in Columbia, MD? Our wedding limousine transportation services are designed to add elegance and ease to your special day. Whether you need a cheap wedding transportation solution in Baltimore County, a classic wedding car hire, a classic wedding car rentals, or chauffeur wedding car hire, we have options to suit every style and budget. Serving Howard County, Baltimore County, Annapolis City, and Harford County, we ensure punctuality and professionalism, making your Wedding party transportation services Annapolis City seamless and stress-free. Our fleet of meticulously maintained vehicles and experienced chauffeurs guarantee a memorable journey from ceremony to reception. Contact us today to reserve your wedding limousine and discover why we're the preferred choice for wedding parties in Harford County.
              </p>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img alt='section-1-img' src='services/Limousine Services page section 02 image.jpg' style={{ height: isMobile ? '350px' : '100%', objectFit: 'cover', objectPosition: 'center', width: '100%', }} />
          </div>
     </section>
     <section style={{padding: '50px', fontSize: '18px', textAlign:'center'}} className='' data-aos="fade-up" data-aos-delay="100">
     Discover the best limo for your wedding with our top-notch transportation services in Columbia, MD. From elegant arrivals to seamless departures, our limo rentals ensure your special day is as luxurious as you imagined. Trust us to exceed your expectations with our dedicated wedding transportation solutions.
     </section>
     <section >
                            <div className='video-section'>
                                <div className="custom-video-background">
                                    <video autoPlay loop muted>
                                        <source src="upload/service-video-bg-n.webm"
                                            type="video/webm" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <div className="custom-content">
                                        <div className='Content-Top'>
                                            <div
                                                className="ms-layer msp-cn-1-1"
                                                style={{}}
                                                data-ease="easeOutQuint"
                                                data-offset-x={0}
                                                data-offset-y={-143}
                                                data-origin="mc"
                                                data-position="normal"
                                            >
                                                Or anywhere you need us to take
                                            </div>
                                            <div
                                                className="ms-layer msp-cn-1-2"
                                                style={{}}
                                                data-ease="easeOutQuint"
                                                data-offset-x={0}
                                                data-offset-y={-58}
                                                data-origin="mc"
                                                data-position="normal"
                                            >

                                                we  take you to anywhere you want to go.
                                            </div>
                                            <div
                                                className="ms-layer msp-cn-1-3"
                                                style={{}}
                                                data-ease="easeOutQuint"
                                                data-offset-x={0}
                                                data-offset-y={24}
                                                data-origin="mc"
                                                data-position="normal"
                                            >
                                                Call Now +1 667-400-0092
                                            </div>
                                            {/* <img
                                                    className="ms-layer"
                                                    src="plugins/masterslider/public/assets/css/blank.gif"
                                                    data-src="upload/slider-divider.png"
                                                    alt="blank"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-type="image"
                                                    data-offset-x={81}
                                                    data-offset-y={92}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                />
                                                <img
                                                    className="ms-layer"
                                                    src="plugins/masterslider/public/assets/css/blank.gif"
                                                    data-src="upload/slider-divider.png"
                                                    alt="blank"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-type="image"
                                                    data-offset-x={-79}
                                                    data-offset-y={92}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                /> */}
                                            <div className="limoking-nav-title">
                                                <div className="limoking-item-title-content-icon-divider-wrapper">
                                                    <div className="limoking-item-title-center-icon-divider" />
                                                    <span style={{ color: '#d59d23' }}>OR</span>
                                                    {/* <FaCarAlt style={{ marginLeft: '12px', marginRight: '12px' }} /> */}
                                                    <div className="limoking-item-title-center-icon-divider" />
                                                </div>
                                            </div>

                                            <div
                                            onClick={() =>{ 
                                          if(location.pathname === '/') {
                                                window.scrollTo({
                                                    top: 400,
                                                    left: 0,
                                                    behavior: 'smooth'
                                                });
                                            }else {
                                                navigate('/', { state: { scrolltoTop: true } })
                                            }
                                          }
                                        }
                                                style={{ maxWidth: 'max-content',cursor:'pointer' }}
                                                // href="service-rates.html"
                                                target="_self"
                                                className="ms-layer msp-cn-2-7 ms-btn ms-btn-round ms-btn-n msp-preset-btn-159"
                                            >
                                                Book Online
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
     </section>
            <FooterSlider/>

    </div>
  </div>
  <div className="clear" />
</div>

  )
}

export default Service1