import React from 'react';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 770
export const LeftSectionContent = ({ title }) => {
    return (
        <div data-aos="fade-up" data-aos-delay="100" className="column-service-content-wrapper">
            <h3 style={{ color: 'rgb(213, 157, 35)', fontSize: isMobile ? '26px' : '65px', textAlign: 'center',marginTop:'10px' }} className="column-service-title">{title}</h3>
        </div>
    );
};


export const LeftSection = ({ title }) => {
    return (
        <div data-aos="fade-up" data-aos-delay="100" className="column-service-content-wrapper">
            <h3 style={{  textAlign: 'center',fontSize: isMobile ? '26px' : '32px', }} className="column-service-title">{title}</h3>
        </div>
    )
}